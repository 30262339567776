<template>
  <div class="content-l">
    <!-- 主页面内容 -->
    <div
      style="background: #ffffff"
      :style="imgqehead > 10 ? 'height:' + imgqehead + 'px;' : ''"
    >
      <div
        class="content"
        :style="imgqehead > 10 ? 'height:' + imgqehead + 'px;' : ''"
      >
        <div class="head">
          <div>今日剩余：{{ total }}个</div>
        </div>
        <div class="rule" @click="isshowpopup = true">规则</div>
        <img
          class="my"
          @click="yaoqcover"
          src="https://ppyos.xijiuyou.com/202201/rdskin-myrds.jpg"
          alt=""
        />
        <img
          class="myyao"
          @click="yaoqcover(1)"
          src="https://ppyos.xijiuyou.com/202201/my-invite-icon.jpg"
          alt=""
        />
        <img class="center-img" :src="img" alt="" />
        <!-- <div class="table-content">
          <div class="table" :style="'width:' + list.length * 85 + 'px;'">
            <div
              v-for="(item, index) in list"
              :key="index"
              :style="
                  ? 'background: linear-gradient(180deg, rgba(152, 255, 145, 1), rgba(123, 234, 255, 1));'
                  : null
              "
              @click="goindex(item, index)"
            >
              <img :src="item.buyGoods.prizeProps.small" alt="" />
            </div>
          </div>
        </div> -->
        <div class="swiper-container">
          <div class="swiper-wrapper">
              <img class="swiper-slide"
              v-for="(item, index) in list"
              :key="index"
              @click="goindex(item)"
              :src="item.buyGoods.prizeProps.small" alt="" />
          </div>
        </div>
        <div class="current-center">
          <div class="current">
            本款封面今日剩余<span>{{ remainder }}</span
            ><span>个</span>
          </div>
          <div class="over" v-if="canBuy">
            <p @click="yuegou">余额购买</p>
            <p
              :style="
                !canFree
                  ? 'background: #dddddd;box-shadow: inset 0px 0px 0px 0px #ffffff;'
                  : ''
              "
              @click="mianfei"
            >
              免费领取
            </p>
          </div>
          <div class="over" v-else>
            <p class="lingq">已领取</p>
          </div>
          <div class="yaoqcover" @click="startShardFriend">
            <p>送你5个封面 免费赠予好友</p>
          </div>
        </div>
        <!-- 规则弹窗 -->
        <xwpopup
          :isshowpopup="isshowpopup"
          @close="closeHandle()"
          :hiddenClose="false"
        >
          <div class="isshowpopup">
            <div>
              <img
                src="https://ppyos.xijiuyou.com/202201/rdskin-rule.jpg"
                alt=""
              />
            </div>
            <div>
              <p>
                1.免费领取对象：活动期间，所有新注册的泡泡鱼玩用户；成功邀请3位好友注册泡泡鱼玩的用户
              </p>
              <p>
                2.领取流程：点击领取——将链接发给任一微信好友——进微信打开链接领取红包
              </p>
              <p>
                3.领取的微信红包封面只能登录泡泡鱼玩的微信账号使用，不能发放到其他微信账号
              </p>
              <p>
                4.支持使用余额购买红包封面，可以通过“赚钱”或“小游戏”频道参与游戏，赚取现金奖励
              </p>
              <p>
                5.微信红包封面有效期自领取之日起3个月内有效，过期即无法使用（微信官方限制）
              </p>
              <p>
                6.每款微信红包封面每个微信账户仅限领取或购买一次，不支持重复购买，不支持退回处理
              </p>
              <p>7.如有其他疑问，请咨询泡泡鱼玩客服QQ1823267163或微信客服paopaoyuvip</p>
            </div>
            <div class="box-sh">
              <div></div>
              <div></div>
            </div>
          </div>
        </xwpopup>
        <xwpopup :isshowpopup="Tips" @close="closeTips()" :hiddenClose="false">
          <div class="isshowpopup isshowpopuptop">
            <div>
              <img
                class="title"
                :style="
                  convertStatus == -2
                    ? 'border: 2px solid #ffffff; border-radius: 50%!important;'
                    : ''
                "
                :src="
                  convertStatus != -2
                    ? 'https://ppyos.xijiuyou.com/202201/rdskin-tips-icon.jpg'
                    : currentAvatar
                "
                alt=""
              />
            </div>
            <p class="name" v-if="convertStatus == -2">
              当前登录微信：{{ currentNickName }}
            </p>
            <p
              class="center"
              v-html="
                convertStatus == -2
                  ? '领取的红包封面仅限<br />泡泡鱼玩绑定微信使用，是否确认领取？'
                  : convertStatus == -1
                  ? '购买本红包封面将扣除您2元余额，确<br />定要购买吗？'
                  : convertStatus == 0
                  ? '您的账户余额不足，请先去”赚钱“频道<br />拿奖励哦'
                  : '您非新注册用户，无法免费领取，可以<br />分享送给好友免费领取哦'
              "
            ></p>
            <div class="btn" v-if="convertStatus == -2 || convertStatus == -1">
              <p @click="Tips = false">取消</p>
              <p @click="wchat">确定</p>
            </div>
            <div class="btnsuc" v-else @click="gocheck">
              <p>{{ convertStatus == 0 ? "好的" : "去邀请" }}</p>
            </div>
          </div>
        </xwpopup>
      </div>
    </div>
    <!-- 分享弹窗 -->
    <xwpopup :isshowpopup="Friend" :hiddenClose="true">
      <div style="width: 100vw; height: 100vh" @click.self="closeFriend()">
        <img class="imgqr-self" v-if="!Friendimg" :src="imgqr" alt="" />
        <div
          class="Friend"
          :style="
            !Friendimg
              ? 'flex-wrap: nowrap;height:122px; padding:24px 10px; 0'
              : ''
          "
        >
          <div @click="wachatf">
            <img
              src="https://ppyos.xijiuyou.com/202201/rdskin-wechat-icon.png"
              alt=""
            />
            <p>微信好友</p>
          </div>
          <div @click="qqfried">
            <img
              src="https://ppyos.xijiuyou.com/202201/rdskin-qq-icon.png"
              alt=""
            />
            <p>QQ好友</p>
          </div>
          <div @click="qqkongj">
            <img
              src="https://ppyos.xijiuyou.com/202201/rdskin-qzone-icon.png"
              alt=""
            />
            <p>QQ空间</p>
          </div>
          <div @click="WbAppInstalled">
            <img
              src="https://ppyos.xijiuyou.com/202201/rdskin-weibo-icon.png"
              alt=""
            />
            <p>新浪微博</p>
          </div>
          <div
            v-if="Friendimg"
            :data-clipboard-text="inviteLink"
            @click="copy"
            class="tag-read"
          >
            <img
              src="https://ppyos.xijiuyou.com/202201/rdskin-link-icon.png"
              alt=""
            />
            <p>复制链接</p>
          </div>
          <div v-if="Friendimg" @click="poster()">
            <img
              src="https://ppyos.xijiuyou.com/202201/rdskin-img-icon.png"
              alt=""
            />
            <p>生成海报</p>
          </div>
          <div v-if="!Friendimg" @click="saveImageBy64">
            <img
              src="https://ppyos.xijiuyou.com/202201/rdskin-download-icon.jpg"
              alt=""
            />
            <p>保存图片</p>
          </div>
        </div>
      </div>
    </xwpopup>
    <!-- 保存图片 -->
    <div
      class="imglogo"
      :style="imgqehead > 10 ? 'height:' + imgqehead + 'px;' : ''"
      ref="bill"
    >
      <div class="imgqr-head2">
        <div>
          <div>
            <img
              class="head"
              :src="currentAvatar"
              crossorigin="anonymous"
              alt=""
            />
            <div>
              <p>{{ currentNickName }}</p>
              <p>我的邀请码：{{ code }}</p>
            </div>
          </div>
          <img
            src="https://ppyos.xijiuyou.com/202201/free-draw-txt.jpg"
            crossorigin="anonymous"
            alt=""
          />
        </div>
        <div class="imgqr-head2-bottom">
          <div id="qrcode1"></div>
          <img
            crossorigin="anonymous"
            src="https://ppyos.xijiuyou.com/202201/ppyw-txt.jpg"
            alt=""
          />
        </div>
      </div>
      <img
        src="https://ppyos.xijiuyou.com/202201/rpskn-bg-n.png"
        crossorigin="anonymous"
        class="imgqr-bottom"
        alt=""
      />
      <img
        class="banner-big-img"
        crossorigin="anonymous"
        :src="bannerimg.image"
        alt=""
      />
      <div class="banner-img">
        <img
          crossorigin="anonymous"
          src="https://ppyos.xijiuyou.com/202201/rpskn-rpl-nl.png"
          alt=""
        />
        <div>
          <img :src="bannerimg.small" crossorigin="anonymous" alt="" />
        </div>
      </div>
    </div>
    <!-- 唤起微信确认弹窗 -->
    <xwpopup :isshowpopup="links" @close="closelinks()" :hiddenClose="false">
      <div class="lingqs">
        <p>领取步骤</p>
        <img
          src="https://ppyos.xijiuyou.com/202201/rdskin-invite-img.jpg"
          alt=""
        />
        <div
          @click="closelinks"
          class="tag-reads"
          :data-clipboard-text="clipboard"
        >
          确定
        </div>
      </div>
    </xwpopup>
  </div>
</template>
<script>
import game from "../../api/game";
import home from "../../api/home";
import { Toast } from "vant";
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import xwpopup from "../../components/Popupbox.vue";
import Clipboard from "clipboard";
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
import "swiper/dist/js/swiper.min";
export default {
  components: {
    xwpopup,
  },
  data() {
    return {
      list: [],//红包列表
      links: false,//唤起微信确认弹窗
      clipboard: "",//复制唤起链接
      img: "",//保存图片base64
      isshowpopup: false,
      total: 0,
      remainder: 0,
      Tips: false,
      canBuy: false,
      canFree: false,
      buyGoodsid: "",
      freeGoodsid: "",
      convertStatus: -2, //-2带微信头像  -1 购买扣钱提示 0余额不足 1非新用户
      currentNickName: "",
      currentAvatar: "",
      code: "",
      imgqehead: 0,
      Friend: false,
      Friendimg: true,
      imgqr: "",
      inviteLink: "",
      bannerimg: {},
      swiper1:'',
    };
  },
  mounted() {
    // 获取分辨率
    try {
      this.imgqehead =
        (window.android.getScreenHeight() / window.android.getScreenWidth()) *
        document.body.clientWidth;
    } catch (err) {
      console.log(err);
      this.imgqehead = 0;
    }
    // 生成二维码
    this.qecode();
    // 列表
    this.wechatRpSkn();
    if (localStorage.getItem("convertStatus")) {
      this.convertStatus = Number(localStorage.getItem("convertStatus"));
    }
  },
  methods: {
    poster() {
      this.Friendimg = false;
    },
    //余额购买
    yuegou() {
      const that =this;
      that.swiper1.autoplay.stop();
      if (this.remainder == 0) {
        Toast("今日已无库存，请明日8:00再来哦~");
        setTimeout(()=>{
          that.swiper1.autoplay.start();
        },100)
      } else {
        this.Tips = true;
        if (localStorage.getItem("convertStatus")) {
          this.convertStatus = localStorage.getItem("convertStatus");
        }
      }
    },
    // 唤起微信红包
    closelinks() {
      this.links = false;
      var clipboard = new Clipboard(".tag-reads");
      clipboard.on("success", () => {
        Toast("复制成功");
        try {
          if (window.android.isWXInstalled()) {
            window.android.shareToWX(
              0,
              3,
              "我要领限量版虎年红包封面",
              "这是我刚领到虎年微信红包封面，想要的话找我，免费送你哦！",
              "https://ppyos.xijiuyou.com/202201/to-tiger-draw.jpg",
              0,
              this.clipboard
            );
          } else {
            Toast("未安装微信");
          }
        } catch (e) {
          Toast("您当前app版本过低请更新app");
        }
      });
    },
    // 微信分享
    wachatf() {
      try {
        if (window.android.isWXInstalled()) {
          if (this.Friendimg) {
            window.android.shareToWX(
              0,
              3,
              "送你一个虎年定制微信红包封面，有10款随你挑哦，限时限量发放",
              "送你一个虎年定制微信红包封面，有10款随你挑哦，限时限量发放",
              "https://ppyos.xijiuyou.com/202201/to-tiger-draw.jpg",
              0,
              this.inviteLink
            );
          } else {
            window.android.shareToWX(
              0,
              2,
              "送你一个虎年定制微信红包封面，有10款随你挑哦，限时限量发放",
              "送你一个虎年定制微信红包封面，有10款随你挑哦，限时限量发放",
              this.imgqr,
              1,
              ""
            );
          }
        } else {
          Toast("未安装微信");
        }
      } catch (e) {
        Toast("您当前app版本过低请更新app");
      }
    },
    //qq分享
    qqfried() {
      try {
        if (window.android.isQQInstalled()) {
          if (this.Friendimg) {
            window.android.shareToQQ(
              0,
              3,
              "我刚发现了一波隐藏福利，免费领虎年微信红包封面，限时限量发放，快来！",
              "我刚发现了一波隐藏福利，免费领虎年微信红包封面，限时限量发放，快来！",
              "https://ppyos.xijiuyou.com/202201/to-tiger-draw.jpg",
              0,
              this.inviteLink
            );
          } else {
            window.android.shareToQQ(
              0,
              2,
              "我刚发现了一波隐藏福利，免费领虎年微信红包封面，限时限量发放，快来！",
              "我刚发现了一波隐藏福利，免费领虎年微信红包封面，限时限量发放，快来！",
              this.imgqr,
              1,
              ""
            );
          }
        } else {
          Toast("未安装QQ");
        }
      } catch (e) {
        Toast("您当前app版本过低请更新app");
      }
    },
    //qq空间
    qqkongj() {
      try {
        if (window.android.isQQInstalled()) {
          if (this.Friendimg) {
            window.android.shareToQQ(
              1,
              3,
              "这里可以免费领虎年微信红包封面，限时限量发放！10款封面随便挑，快戳这里~",
              "这里可以免费领虎年微信红包封面，限时限量发放！10款封面随便挑，快戳这里~",
              "https://ppyos.xijiuyou.com/202201/to-tiger-draw.jpg",
              0,
              this.inviteLink
            );
          } else {
            window.android.shareToQQ(
              1,
              2,
              "这里可以免费领虎年微信红包封面，限时限量发放！10款封面随便挑，快戳这里~",
              "这里可以免费领虎年微信红包封面，限时限量发放！10款封面随便挑，快戳这里~",
              this.imgqr,
              1,
              ""
            );
          }
        } else {
          Toast("未安装QQ");
        }
      } catch (e) {
        Toast("您当前app版本过低请更新app");
      }
    },
    // 微博
    WbAppInstalled() {
      try {
        if (window.android.isWbAppInstalled()) {
          if (this.Friendimg) {
            window.android.shareToWB(
              0,
              3,
              "每日8:00免费领取#微信红包封面# 虎年专属定制10款任你挑，快来收集限量版#红包封面#",
              "每日8:00免费领取#微信红包封面# 虎年专属定制10款任你挑，快来收集限量版#红包封面#",
              "https://ppyos.xijiuyou.com/202201/to-tiger-draw.jpg",
              0,
              this.inviteLink
            );
          } else {
            window.android.shareToWB(
              0,
              2,
              "每日8:00免费领取#微信红包封面# 虎年专属定制10款任你挑，快来收集限量版#红包封面#",
              "每日8:00免费领取#微信红包封面# 虎年专属定制10款任你挑，快来收集限量版#红包封面#",
              this.imgqr,
              1,
              ""
            );
          }
        } else {
          Toast("未安装微博");
        }
      } catch (e) {
        Toast("您当前app版本过低请更新app");
      }
    },
    // 复制
    copy() {
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", () => {
        Toast("复制成功");
      });
      this.closeFriend();
    },
    // 保存图片
    saveImageBy64() {
      const that =this;
      this.Friend = false;
      that.swiper1.autoplay.start();
      try {
        window.android.saveImageBy64(this.imgqr);
      } catch (e) {
        Toast("您当前app版本过低请更新app");
      }
    },
    // 关闭分享弹窗
    closeFriend() {
      const that =this;
      this.Friend = false;
      that.swiper1.autoplay.start();
    },
    // 生成二维码
    qecode() {
      home.myInviteLink().then((res) => {
        this.inviteLink = res.data.inviteLink + "&type=2";
        document.getElementById("qrcode1").innerHTML = "";
        new QRCode("qrcode1", {
          width: 64, // 二维码宽度，单位像素
          height: 64, // 二维码高度，单位像素
          text: res.data.inviteLink + "&type=2", // 生成二维码的链接
        });
        this.code = res.data.code;
      });
    },
    //分享好友弹窗
    startShardFriend() {
      const that =this;
      that.swiper1.autoplay.stop();
      this.Friend = true;
      this.Friendimg = true;
      setTimeout(() => {
        html2canvas(this.$refs.bill, { useCORS: true }).then((res) => {
          this.imgqr = res.toDataURL("image/png");
        });
      }, 100);
    },
    // 免费领取
    mianfei() {
      const that =this;
      that.swiper1.autoplay.stop();
      if (this.remainder == 0) {
        Toast("今日已无库存，请明日8:00再来哦~");
        setTimeout(()=>{
          that.swiper1.autoplay.start();
        },100)
        return false;
      }
      if (!this.canFree) {
        this.convertStatus = 1;
        this.Tips = true;
      } else {
        const data = {
          goodsId: this.freeGoodsid,
        };
        game.wechatRpSknConvert(data).then((res) => {
          if (res.status == 984301) {
            this.convertStatus = 0;
          } else if (res.status == 0) {
            Toast("领取成功");
            this.Tips = false;
            setTimeout(() => {
              game.getRpSknInfo({ orderId: res.data.orderId }).then((_res) => {
                this.clipboard = _res.data.skinUrl;
                this.links = true;
              });
            }, 300);
            this.wechatRpSkn();
          }
        });
      }
    },
    // 确认购买
    wchat() {
      if (this.convertStatus == -2) {
        this.convertStatus = -1;
        localStorage.setItem("convertStatus", -1);
      } else if (this.convertStatus == -1) {
        this.purchase();
      }
    },
    gocheck() {
      if (this.convertStatus == 0) {
        this.Tips = false;
        this.convertStatus = -1;
      } else {
        this.startShardFriend();
      }
    },
    //购买接口调用
    purchase() {
      const data = {
        goodsId: this.buyGoodsid,
      };
      game.wechatRpSknConvert(data).then((res) => {
        console.log(res);
        if (res.status == 984301) {
          this.convertStatus = 0;
        } else if (res.status == 0) {
          Toast("领取成功");
          this.Tips = false;
          this.wechatRpSkn();
          setTimeout(() => {
            game.getRpSknInfo({ orderId: res.data.orderId }).then((_res) => {
              this.clipboard = _res.data.skinUrl;
              this.links = true;
            });
          }, 300);
        }
      });
    },
    closeHandle() {
      this.isshowpopup = false;
    },
    closeTips() {
      const that =this;
      that.swiper1.autoplay.start();
      this.Tips = false;
    },
    // 红包列表
    wechatRpSkn() {
      const that =this;
      game.wechatRpSkn().then((res) => {
        this.list = res.data.rpItems;
        this.img = res.data.rpItems[0].buyGoods.prizeProps.image;
        this.total = res.data.total;
        this.remainder = res.data.rpItems[0].remainder;
        this.canBuy = res.data.rpItems[0].canBuy;
        this.canFree = res.data.rpItems[0].canFree;
        this.buyGoodsid = res.data.rpItems[0].buyGoods.goodId;
        this.freeGoodsid = res.data.rpItems[0].freeGoods.goodId;
        this.bannerimg = res.data.rpItems[0].buyGoods.prizeProps;
        this.currentAvatar = res.data.currentAvatar;
        this.currentNickName = res.data.currentNickName;
        // 轮播图
        setTimeout(()=>{
          that.swiper1 = new Swiper('.swiper-container', {
            slidesPerView: 4,
            spaceBetween: 30,
            autoplay: {
              delay: 2000,
              stopOnLastSlide: false,
              disableOnInteraction: true,
            },
            loop:true,
            centeredSlides: true,
            on: {
              slideChangeTransitionEnd: function(){
                if(that.list[this.realIndex]){
                  that.img = that.list[this.realIndex].buyGoods.prizeProps.image
                  that.bannerimg = that.list[this.realIndex].buyGoods.prizeProps;
                  that.remainder = that.list[this.realIndex].remainder;
                  that.canBuy = that.list[this.realIndex].canBuy;
                  that.canFree = that.list[this.realIndex].canFree;
                  that.buyGoodsid = that.list[this.realIndex].buyGoods.goodId;
                  that.freeGoodsid = that.list[this.realIndex].freeGoods.goodId;
                }else{
                  that.img = that.list[0].buyGoods.prizeProps.image
                  that.bannerimg = that.list[0].buyGoods.prizeProps;
                  that.remainder = that.list[0].remainder;
                  that.canBuy = that.list[0].canBuy;
                  that.canFree = that.list[0].canFree;
                  that.buyGoodsid = that.list[0].buyGoods.goodId;
                  that.freeGoodsid = that.list[0].freeGoods.goodId;
                }
              },
            }
          });
        },100)
        
      });
    },
    // 跳转邀请或者红包列表
    yaoqcover(index) {
      if(index==1){
        this.$router.push({
          name: "loading",
        });
      }else{
        this.$router.push({
          name: "mycover",
        });
      }
      
    },
    // 点击切换
    goindex(item) {
      this.bannerimg = item.buyGoods.prizeProps;
      this.remainder = item.remainder;
      this.img = item.buyGoods.prizeProps.image;
      this.canBuy = item.canBuy;
      this.canFree = item.canFree;
      this.buyGoodsid = item.buyGoods.goodId;
      this.freeGoodsid = item.freeGoods.goodId;
      const that = this;
      that.swiper1.slideTo(that.swiper1.clickedIndex, 1000, false);
      setTimeout(()=>{
        that.swiper1.autoplay.start();
      },100)
    },
  },
};
</script>
<style scoped>
.content {
  width: 100vw;
  min-height: 100vh;
  padding-top: 8px;
  background: url("https://ppyos.xijiuyou.com/202201/rdskin-bg.jpg")
    no-repeat;
  background-size: 100%;
  text-align: center;
  padding-bottom: 24px;
  position: relative;
  background-color: #ffffff;
  z-index: 100;
}
.head {
  width: 100vw;
  height: 140px;
  position: relative;
  background: url("https://ppyos.xijiuyou.com/202201/rkskin-tp-banner.jpg")
    no-repeat;
  background-size: 100%;
}
.head div {
  width: 149px;
  height: 37px;
  position: absolute;
  bottom: 13px;
  left: calc(50% - 74px);
  background: url("https://ppyos.xijiuyou.com/202201/rdskin-banner-bg.jpg")
    no-repeat;
  background-size: 100%;
  font-size: 14px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #fff7cf;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rule {
  position: absolute;
  right: 0;
  top: 60px;
  width: 20px;
  height: 42px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  border-radius: 8px 0px 0px 8px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff1d59;
  text-align: center;
  line-height: 17px;
  display: flex;
  align-items: center;
}
.my {
  position: absolute;
  right: 0;
  width: 49px;
  height: 53px;
  top: 160px;
  box-shadow: 0px 4px 8px 0px rgba(255, 52, 52, 0.4),
    inset 0px 2px 4px 0px rgba(255, 59, 0, 0.43);
  border-radius: 12px 0px 0px 12px;
}
.myyao{
  position: absolute;
  right: 0;
  width: 49px;
  height: 53px;
  top: 230px;
  box-shadow: 0px 4px 8px 0px rgba(255, 52, 52, 0.4),
    inset 0px 2px 4px 0px rgba(255, 59, 0, 0.43);
  border-radius: 12px 0px 0px 12px;
}
.yaoqcover {
  margin-top: 7px;
  width: 100%;
}
.yaoqcover p {
  width: calc(100% - 44px);
  background: linear-gradient(270deg, #ff674b 0%, #ff7c53 100%);
  box-shadow: inset 0px -3px 0px 0px rgba(237, 0, 32, 0.4),
    inset 0px -1px 5px 0px rgba(255, 218, 162, 0.3);
  border-radius: 30px;
  height: 48px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.center-img {
  width: calc(100% - 157px);
  margin-bottom: 12px;
  border-radius: 10px;
}
/* .table-content {
  width: 100vw;
  overflow-x: auto;
  margin-top: -75px;
}
.table {
  height: 93px;
  display: flex;
}
.table div {
  width: 85px;
  margin: 0 7px;
  padding: 3px;
  border-radius: 5px;
}
.table div img {
  width: 100%;
  height: 100%;
} */
.current {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-top: 13px;
}
.current span {
  font-size: 18px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #333333;
  margin-left: 8px;
}
.current span:nth-child(2) {
  font-size: 14px;
  margin: 0;
}
.over {
  display: flex;
  justify-content: space-around;
  margin-top: 7px;
}
.over p {
  width: 152px;
  height: 48px;
  background: linear-gradient(
    90deg,
    rgba(254, 197, 129, 0.19) 0%,
    rgba(234, 160, 89, 0.19) 100%
  );
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #eca45d;
}
.over p:nth-child(2) {
  background: linear-gradient(90deg, #fec581 0%, #eaa059 100%);
  box-shadow: inset 0px -3px 0px 0px rgba(201, 30, 0, 0.2);
  color: #ffffff;
}
.over .lingq {
  width: 152px;
  height: 48px;
  background: #dddddd;
  border-radius: 30px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}
.isshowpopup {
  width: calc(100% - 70px);
  margin-top: 80px;
  background: #ffffff;
  border-radius: 15px;
  position: relative;
}
.box-sh div:nth-child(1){
  position: absolute;
  z-index: 1;
  bottom: 20px;
  left: 0;
  height: 55px!important;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%) !important;
  border-radius: 15px;
  padding: 0!important;
}
.box-sh div:nth-child(2){
  position: absolute;
  z-index: 1;
  bottom: 0px;
  left: 0;
  height: 20px!important;
  width: 100%;
  background: #ffffff;
  border-radius: 15px;
  padding: 0!important;
}
.isshowpopup div:nth-child(1) {
  width: 100%;
  height: 42px;
  background: linear-gradient(180deg, #ffc9be 0%, rgba(255, 218, 190, 0) 100%);
  border-radius: 15px 15px 0 0;
}
.isshowpopup div:nth-child(1) img {
  width: calc(100% - 120px);
  margin-top: -19px;
}
.isshowpopup div:nth-child(2) {
  padding: 0 18px 30px 28px;
  height: 330px;
  text-align: left;
  overflow-y: auto;
}
.isshowpopup div:nth-child(2) p {
  text-indent: -10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 25px;
  color: #444444;
}
.isshowpopup .title {
  width: 66px !important;
  height: 66px !important;
  margin-top: -30px !important;
}
.isshowpopup .btn {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 30px;
  width: calc(100% - 40px);
}
.isshowpopup .btn p {
  width: 120px !important;
  height: 44px !important;
  background: #eeeeee !important;
  border-radius: 25px !important;
  font-size: 14px !important;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400 !important;
  color: #444444 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-indent: 0 !important;
}
.isshowpopup .btn p:nth-child(2) {
  background: linear-gradient(90deg, #fec581 0%, #eaa059 100%) !important;
  box-shadow: inset 0px -3px 0px 0px rgba(201, 30, 0, 0.2);
  color: #ffffff !important;
}
.isshowpopup .btnsuc {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 90px);
  width: 210px;
  height: 44px;
  background: linear-gradient(90deg, #fec581 0%, #eaa059 100%);
  box-shadow: inset 0px -3px 0px 0px rgba(201, 30, 0, 0.2);
  border-radius: 25px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto 30px;
}
.isshowpopup .name {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  margin-top: 4px;
}
.isshowpopup .center {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 25px;
  margin: 20px 0;
}
.isshowpopuptop {
  margin-top: 150px;
  padding: 2px;
}

.imgqr-head2 {
  width: calc(100% - 24px);
  background: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  position: absolute;
  bottom: 12px;
  z-index: 1;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.imgqr-head2 div div:nth-child(1) {
  display: flex;
}
.imgqr-head2 div div:nth-child(1) .head {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
}
.imgqr-head2 div div:nth-child(1) p:nth-child(1) {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  text-align: left;
}
.imgqr-head2 div div:nth-child(1) p:nth-child(2) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  margin-top: 4px;
  white-space: nowrap;
}
.imgqr-head2 div img:nth-child(2) {
  width: 89%;
  margin: 0 auto;
  margin-top: 9px;
}
.imgqr-head2-bottom {
  font-size: 16px;
  font-family: HelloFont-WenYiHei, HelloFont;
  font-weight: normal;
  color: #444444;
}
.imgqr-head2-bottom img {
  margin-top: 4px;
  width: 64px;
}
.imglogo {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
}
.imgqr-bottom {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.Friend {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: #ffffff;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  padding: 24px 10px 0;
}
.Friend div {
  width: 25%;
  text-align: center;
  margin-bottom: 20px;
}
.Friend div img {
  width: 52px;
  height: 52px;
}
.Friend div p{
  margin-top: 5px;
}
.imgqr-self {
  width: calc(100% - 100px);
  margin-top: 20px;
}
.lingqs {
  width: calc(100% - 70px);
  background: #ffffff;
  border-radius: 15px;
  margin-top: 100px;
  padding: 15px 30px 30px;
}
.lingqs p {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
}
.lingqs img {
  width: 100%;
  margin: 15px 0 20px;
}
.lingqs div {
  width: 210px;
  height: 44px;
  background: linear-gradient(90deg, #fec581 0%, #eaa059 100%);
  box-shadow: inset 0px -3px 0px 0px rgba(201, 30, 0, 0.2);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
/* .content-bottom{
  position: fixed;
  bottom: 0;
  padding-bottom: 24px;
} */
.banner-img {
  position: absolute;
  bottom: 109px;
}
.banner-img img {
  width: 100%;
}
.banner-img div {
  width: 30%;
  left: 35%;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(152, 255, 145, 1),
    rgba(123, 234, 255, 1)
  );
  border-radius: 5px;
  padding: 5px;
}
.banner-img div img {
  width: 100%;

  overflow: hidden;
}
.banner-big-img {
  position: absolute;
  top: 130px;
  width: 55%;
}
.current-center{
  background: #ffffff;
  padding-bottom: 24px;
}
.swiper-container {
  width: 100%;
  /* height: 100%; */
  margin-top: -75px;
}
.swiper-slide {
  /* width: 80px; */
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-slide{
  padding: 5px;
}
.swiper-slide-active{
  background: linear-gradient(180deg, rgba(152, 255, 145, 1), rgba(123, 234, 255, 1));
  border-radius: 10px;
}
</style>